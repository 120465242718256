@use "./config/" as *;
@forward "./main-scss/";
@forward "./author/authorlisting";
@forward "./listing/related-list";
@forward "./partials/pagination";

.waf-authorlisting {
    padding-block: var(--space-5);
    @include listing-card(horizontal);
    @extend %relative;
    @extend %my-4;
    @extend %pure-white-900-bg;
    .head-tab {
        @extend %d-none;
    }
    .pagination-wrap {
        @extend %flex;
    }
    .article {
        &-list {
            padding-top: unset;
            flex-wrap: wrap;
            overflow-x: unset;
            @include card-count(1, var(--space-4), true);
            @extend %pl-10;
        }
        &-wrap {
            @extend %flex;
        }
        &-title {
            @extend %font-16;
        }
        &-content {
            width: 100%;
            padding: 0;
            @extend %my-auto;
            @extend %text-left;
        }
        &-more {
            justify-content: flex-start;
        }
        &-text {
            @include truncate(2, 12);
            height: auto;
        }
        &-thumbnail {
            margin-block: auto;
            width: 10rem;
            margin-inline: var(--space-12-neg) var(--space-4);
        }
        &-item {
            background: var(--neutral-50);
            @extend %py-4;
            @extend %pr-4;
        }
    }
    .filter-section {
        position: unset;
        @extend %d-block;
        .filter-wrap {
            @extend %flex;
        }
        .waf-select-box {
            & > * {
                all: unset;
            }
            &::before {
                width: 100%;
                left: 0;
            }
        }
    }
}
.filter-section {
    @extend %mb-4;
    .filter-actions,
    .more-filters {
        @extend %d-none;
    }
    .waf-select-box {
        @extend %pt-0;
        @extend %px-0;
        @extend %pb-1;
        .selected-title {
            .sub-title {
                width: max-content;
                @extend %mr-3;
                @extend %mb-2;
                @extend %font-12-pb;
            }
            .title {
                @extend %d-none;
            }
        }
        .select-list {
            @extend %flex;
            .list-item {
                opacity: 0.5;
                width: max-content;
                @extend %px-3;
                &:first-child {
                    @extend %pl-0;
                }
                &.active {
                    opacity: 1;
                }
                &:not(:last-child):after {
                    content: "";
                    width: .1rem;
                    right: 0;
                    @extend %w-50;
                    @extend %neutral-100-bg;
                    @extend %absolute;
                }
            }
            .list-item-text {
                @extend %font-12-pb;
            }
        }
    }
    .select-box-wrap {
        position: unset;
        @extend %d-block;
    }
    .list-item-action {
        white-space: nowrap;
    }
    .selected-title {
        @extend %mb-1;
        &::after {
            @extend %d-none;
        }
    }
}
.waf-listing {
    .meta-author {
        @extend %d-none;
    }
}
@include mq(col-md) {
    .waf-authorlisting {
        .sub-title {
            display: block;
        }
        .waf-select-box {
            background-color: unset;
        }
    }
    .filter-section {
        padding-bottom: var(--space-4);
    }
}
@include mq(col-lg) {
    .waf-authorlisting {
        padding-inline: var(--space-4);
        .article-list {
            @include card-count(2, var(--space-16), true);
            row-gap: var(--space-4);
        }
        .filter-section {
            position: absolute;
            right: 0;
            top: var(--space-4);
            width: max-content;
            .waf-select-box {
                display: flex;
                align-items: center;
                .selected-title .sub-title {
                    margin-bottom: 0;
                }
            }
        }
    }
}